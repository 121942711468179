type StructuredDataProps = {
  schema: {
    '@context': string;
    [key: string]: any;
  };
}; // TODO: use https://github.com/google/schema-dts/tree/main ?

const indentationSpaceNumber = 2;

const StructuredData = ({ schema }: StructuredDataProps) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(schema, undefined, indentationSpaceNumber) }}
  />
);

export default StructuredData;
