import countryMapping from '../constants/countryLanguageMap';

export const getLanguagesList = () => {
  const languageLists = process.env.GATSBY_LANGUAGE_CODENAMES?.split(',').filter(Boolean);
  return languageLists?.length ? languageLists : ['en'];
};

export const getPathWithLanguage = (path: string, language: string) => {
  const languageToAppend = language !== 'en' ? `/${language}` : '';

  if (getLanguageFromPath(path) === language) {
    return path;
  }

  const regexLanguagePrefix = new RegExp(`^/(${getLanguagesList().join('|')})/`, 'gi');
  const pathWithoutLanguage = path.replace(regexLanguagePrefix, '/');

  return `${languageToAppend}${pathWithoutLanguage}`;
};

export const getLanguageFromPath = (pathname: string) =>
  getLanguagesList().find((lang) => pathname.startsWith(`/${lang}/`)) ?? 'en';

export const getAlternateLinks = (url: string, pathname: string) =>
  getLanguagesList().map((lang) => ({
    hreflang: lang === 'en' ? 'x-default' : countryMapping[lang] ?? lang,
    href: `${url}${getPathWithLanguage(pathname, lang)}`,
  }));

export const isPageTranslated = ({
  preferredLanguage, // NOTE: The language set by the URL, i.e /en/ or /de/
  pageLanguage, // NOTE: The language of the page retrieved from the CMS
}: {
  preferredLanguage?: string;
  pageLanguage?: string;
}) => preferredLanguage === pageLanguage;
