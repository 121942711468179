const getInternalLinks = (pageTree: Queries.GetPageTreeQueryQuery): InternalLinks => {
  const links: InternalLinks =
    pageTree?.allKenticoLink?.nodes.reduce((all, current) => {
      if (!(current.language in all)) {
        all[current.language] = {};
      }

      all[current.language][current.codename] = current.url;
      return all;
    }, {} as InternalLinks) || {};

  return links;
};

export default getInternalLinks;
